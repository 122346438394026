import React, { useState } from "react";
import {
  createFungible,
  mplTokenMetadata,
} from "@metaplex-foundation/mpl-token-metadata";
import {
  createTokenIfMissing,
  getSplAssociatedTokenProgramId,
} from "@metaplex-foundation/mpl-toolbox";
import { generateSigner, percentAmount } from "@metaplex-foundation/umi";
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
import { base58 } from "@metaplex-foundation/umi/serializers";
import { walletAdapterIdentity } from "@metaplex-foundation/umi-signer-wallet-adapters";
import { connection } from "../constants/constants";
import axios from "axios";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";

const TokenMinter = ({ wallet, isConnected, isOpen, setIsOpen }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const user = useSelector((state) => state.userStore.user);
  const token = user.auth;

  const createAndMintTokens = async () => {
    await wallet.connect();

    try {
      setLoading(true);
      const umi = createUmi(connection).use(mplTokenMetadata());

      // Use the wallet's public key as the identity
      umi.use(walletAdapterIdentity(wallet));

      // Create fungible token
      const mintSigner = generateSigner(umi);

      const createFungibleIx = createFungible(umi, {
        mint: mintSigner,
        name: "LF Coin",
        uri: "https://amber-adorable-bobolink-134.mypinata.cloud/ipfs/QmYfLgDckLaBeMg8MQixfRP2Lta172cNC1GEt3GZ2cHmAL",
        sellerFeeBasisPoints: percentAmount(0),
        decimals: 6,
      });

      // Create token account if missing
      const createTokenIx = createTokenIfMissing(umi, {
        mint: mintSigner.publicKey,
        owner: wallet.publicKey,
        ataProgram: getSplAssociatedTokenProgramId(umi),
      });

      // Send transaction
      console.log("Sending transaction");
      const tx = await createFungibleIx.add(createTokenIx).sendAndConfirm(umi);

      // Deserialize signature
      const signature = base58.deserialize(tx.signature)[0];

      const tokenAddress = mintSigner.publicKey;

      // Log transaction details
      console.log("\nTransaction Complete tx", tx);
      console.log("View Transaction on Solana Explorer");
      console.log(`https://explorer.solana.com/tx/${signature}?cluster=devnet`);
      console.log("View Token on Solana Explorer");
      console.log(
        `https://explorer.solana.com/address/${mintSigner.publicKey}?cluster=devnet`
      );
      const details = await getTransactionDetails(signature, tokenAddress);
      console.log("details", details);

      await sendTransactionDetails(details);
      Swal.fire({
        title: "Success!",
        text: "Token created successfully!",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: "Close",
        confirmButtonText: "Check on Explorer",
      }).then((result) => {
        if (result.isConfirmed) {
          if (!signature) {
            console.error("Signature not available");
            return;
          }
          const url = `https://explorer.solana.com/address/${mintSigner.publicKey}?cluster=devnet`;

          window.open(url, "_blank", "noopener,noreferrer");
        }
      });
      setMessage(
        "Note: Ensure to mint the newly created token to complete the deployment and apply over the application"
      );
      setLoading(false);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Token creation failed. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error processing transaction:", error);
      setLoading(false);
    }
  };

  const getTransactionStatus = async (signature) => {
    try {
      const status = await connection.getSignatureStatus(signature);

      if (status?.value?.err === null) {
        console.log("Transaction completed successfully");
        return "Success";
      } else {
        console.log("Transaction failed with error: ", status?.value?.err);
        return "Failure";
      }
    } catch (err) {
      console.log("Transaction failed");
    }
  };

  const getTransactionDetails = async (signature, tokenAddress) => {
    const status = await getTransactionStatus(signature);
    const tokenContractAddress = tokenAddress;
    const deploymentwalletAddress = isConnected.toBase58();

    return {
      signature,
      status,
      // createdAt,
      tokenContractAddress,
      deploymentwalletAddress,
    };
  };

  const sendTransactionDetails = async (details) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/deployment/storeTokenDeployment`,
        details,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("create token details sent to backend successfully", details);
    } catch (error) {
      console.error("Error sending transaction details:", error);
    }
  };

  const handleConnectWallet = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div>
        <h3>Create New Token</h3>
      </div>
      <div className="create-token-message">{message ? message : ""}</div>
      {isConnected ? (
        <div className="connect-wallet-one">
          <Button onClick={createAndMintTokens} disabled={loading}>
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Create Now"
            )}
          </Button>
        </div>
      ) : (
        <div className="connect-wallet-one">
          <button onClick={handleConnectWallet}>Connect Wallet</button>
        </div>
      )}
    </>
  );
};

export default TokenMinter;
