import React, { useState, useEffect } from "react";
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validateNoNumbersOrSpecialChars,
} from "../../utils/validation";
import lineImage from "../../assets/images/line-art-large.png";
import Swal from "sweetalert2";
import axios from "axios";
import "react-international-phone/style.css";
import { useNavigate } from "react-router-dom";
import ImageGif from "../../assets/images/Player_24_Emote.gif";
import { useDispatch, useSelector } from "react-redux";
import { signupNewsletter  } from "../../store/reducers/signupNewsletterSlice";

const Content = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorCouponCode, setErrorCouponCode] = useState("");
  // const [loading, setLoading] = useState(false);
  const [errorAcceptance, setErrorAcceptance] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCouponUsed, setIsCouponUsed] = useState(false);
  const navigate = useNavigate();

  // const [isAgree, setIsAgree] = useState(false);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (isAgree) {
  //     dispatch(signupNewsletter(email));
  //   } else {
  //     alert('You must agree to the terms to sign up.');
  //   }
  // };



  const dispatch = useDispatch();
  const { loading, error, success } = useSelector((state) => state?.signUpEvents);

  function formatPhone(value) {
    let format = "";
    var numbers = value.replace(/\D/g, ""),
      char = { 0: "+1 (", 3: ") ", 6: " - " };
    if (numbers[0] === "1") numbers = numbers.slice(1);
    format = "";
    for (var i = 0; i < numbers.length; i++) {
      format += (char[i] || "") + numbers[i];
    }
    return format;
  }

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, "").substring(0, 11);
    let formattedPhone = formatPhone(value);
    setPhone(formattedPhone);
    // console.log(formattedPhone);
    const cleanedPhone = formattedPhone.replace(/\D/g, "");
    if (cleanedPhone.length === 11) {
      setErrorPhone("");
    } else if (cleanedPhone.length > 1 && cleanedPhone.length < 11) {
      setErrorPhone("Incomplete phone number");
    }
  };

  const targetDate = new Date("2025-04-30");
  const calculateTimeLeft = () => {
    const difference = targetDate - new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    } else {
      // If the countdown is over
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  // const handleSignUp = (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   if (!validateFirstName(firstname)) {
  //     setErrorFirstName(
  //       firstname.length > 0 && firstname.length < 3
  //         ? "First Name must be at least 3 characters long"
  //         : "Please enter first name"
  //     );
  //     setLoading(false);
  //     return;
  //   }

  //   if (!validateLastName(lastname)) {
  //     setErrorLastName(
  //       lastname.length > 0 && lastname.length < 3
  //         ? "Last Name must be at least 3 characters long"
  //         : "Please enter last name"
  //     );
  //     setLoading(false);
  //     return;
  //   }

  //   if (!validateEmail(email)) {
  //     setErrorEmail(
  //       email.length > 0 ? "Invalid email format" : "Please enter email"
  //     );
  //     setLoading(false);
  //     return;
  //   }

  //   if (!isAccepted) {
  //     setErrorAcceptance("Please accept the terms and conditions");
  //     setLoading(false);
  //     return;
  //   }

  //   const userData = {
  //     firstname,
  //     lastname,
  //     phone,
  //     email,
  //   };

  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_ENDPOINT_URL}/users/promotionalSignup`,
  //       userData
  //     )
  //     .then((response) => {
  //       if (response.data.status === 200) {
  //         setIsSuccess(true);
  //         setLoading(false);
  //         setTimeout(() => {
  //           setFirstName("");
  //           setLastName("");
  //           setPhone("");
  //           setEmail("");
  //           setErrorFirstName("");
  //           setErrorLastName("");
  //           setErrorPhone("");
  //           setErrorEmail("");
  //           setIsAccepted(false);
  //           setErrorAcceptance("");
  //         }, 8000);
  //       } else {
  //         Swal.fire({
  //           title: "Error!",
  //           text: `${response.data.message}`,
  //           icon: "error",
  //           confirmButtonText: "OK",
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         title: "Error!",
  //         text: "Something Went Wrong! Please try again",
  //         icon: "error",
  //         confirmButtonText: "OK",
  //       });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  //   // }
  // };

  // const handelHome = () => {
  //   setIsSuccess(false);
  // };

  // const capitalizeFirstLetter = (string) => {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorEmail("");
    setErrorAcceptance("");
     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
    if (!email) {
      // setErrorEmail("Email is required");
      Swal.fire({
        title: "Error",
        text: "Email is required!",
        icon: "error",
        confirmButtonText: "Ok",
      });
       return;
    } else if (!emailPattern.test(email)) {
      Swal.fire({
        title: "Success!",
        text: "Please enter a valid email address!",
        icon: "success",
        confirmButtonText: "Ok",
      });
      // setErrorEmail("Please enter a valid email address");
      // return;
    }

    if (!isAccepted) {
      Swal.fire({
        title: "Error",
        text: "You must accept the terms and conditions!",
        icon: "error",
        confirmButtonText: "Ok",
      });
      // setErrorAcceptance("You must accept the terms");
      return;
    }
    // Dispatch the signup action
    dispatch(signupNewsletter({ email, is_agree: isAccepted }));
  };

  // Show success alert when signup is successful
  useEffect(() => {
    if (!loading && success) {
      Swal.fire({
        title: "Success!",
        text: "You have successfully subscribed to the Newsletter.",
        icon: "success",
        confirmButtonText: "Ok",
      });

      // Reset form fields after success
      setEmail("");
      setIsAccepted(false);
      setErrorEmail("");
      setErrorAcceptance("");
    } else if (!loading && error) {
      // Display the error message returned from the API
      const errorMessage = error || "An error occurred. Please try again.";
      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }, [loading, success, error]);


  // const CouponSuccessMessage = () => (
  //   <>
  //     <div className="ani-image-home">
  //       <img src={lineImage} alt="img" />
  //     </div>
  //     <div className="home-btn-thanks">
  //       <button className="home-btn" type="button" onClick={handelHome}>
  //         <i class="fa fa-arrow-left" aria-hidden="true"></i>
  //       </button>
  //     </div>

  //     <div className="lend-cont-thanks">
  //       <div className="container">
  //         <div className="lend-cont-thanks-head">
  //           <h4>WELCOME TO THE COMMUNITY ! </h4>
  //           <div className="ifcoin-gif">
  //             <img src={ImageGif} alt="lfcoin" />
  //           </div>
  //           <p>
  //             Don't have a Solana wallet yet? No worries! You'll need one to
  //             receive your $LAL coins, and we'll provide you with a guide to
  //             help you set it up. Stay tuned, and thank you for joining the
  //             LFCoin community!
  //           </p>
  //           <p>Please check your email for further details.</p>
  //         </div>
  //         <div className="iframe-video">
  //           <iframe
  //             width=""
  //             height=""
  //             src="https://www.youtube.com/embed/7V_Phj2Ndb4?si=ikAAN6MAZZLj1XiM"
  //             title="YouTube video player"
  //             frameborder="0"
  //             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  //             referrerpolicy="strict-origin-when-cross-origin"
  //             allowFullScreen
  //           ></iframe>
  //         </div>
  //         <div className="lend-cont-thanks-inner-in">
  //           <p>
  //             <strong>Disclosure: </strong>
  //             LFCOIN $LAL is a deflationary MEME COIN. LFCOIN $LAL is not
  //             affiliated with the Los Angeles Lakers or the National Basketball
  //             Association (NBA). $LAL is not a security and carries no intrinsic
  //             value. Its primary purpose is to serve as a utility token within a
  //             crypto community, supporting engagement and interactions.
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );

  const SuccessMessage = () => (
    <>
      <div className="ani-image-home">
        <img src={lineImage} alt="img" />
      </div>

      <div className="home-btn-thanks">
        <button className="home-btn" type="button"
        // onClick={handelHome}
        >
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
      </div>

      <div className="lend-cont-thanks">
        <div className="lend-cont-thanks-head">
          <h4>Thank you for Registration!</h4>
        </div>
        <div className="lend-cont-thanks-inner">
          <h4>Disclosure</h4>
          <p>
            LF Coin ($LAL) is a community-driven meme coin created to celebrate
            sports fandom and foster community engagement. LF Coin is not
            affiliated with the Los Angeles Lakers, the National Basketball
            Association (NBA), or any associated organizations. The project
            operates independently and is solely focused on building a
            decentralized community of sports enthusiasts. As a meme coin, LF
            Coin carries no intrinsic value and should not be considered an
            investment or security.
          </p>
        </div>
      </div>
    </>
  );

  return (
    <>
      {!isSuccess && (
        <div className="home-container home-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="lend-cont comin-soon-lendcount">
                  <div className="lend-cont-inner comingsoon-lend-count">
                    <h4>LFCoin ($LAL) Coming soon</h4>
                    <p>
                      is a community-driven cryptocurrency built on the Solana
                      blockchain, designed to engage and reward a passionate
                      community of sports fans. By participating in games,
                      activities, and governance, LF Coin offers fans a unique
                      way to celebrate sports fandom. This whitepaper outlines
                      the technical specifications, tokenomics, and utility of
                      LF Coin, showcasing how the ecosystem operates and
                      benefits holders. LF Coin incorporates limited DAO
                      mechanics, giving token holders basic voting rights on
                      project proposals. Deeper levels of community engagement
                      and governance are unlocked through ownership of our
                      digital membership, which provides access to exclusive
                      decision-making opportunities, events, and other benefits.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 countdown-timer-section">

                <div className="countdown-timer">
                  <h1>Countdown to Launch</h1>
                  <div className="time-container countdown">
                    <div id="tiles">
                      <div className="time-block">
                        <span className="label">Days</span>
                        <br />
                        <span className="time">{timeLeft.days}</span>
                      </div>
                      <div className="time-block">
                        <span className="label">Hours</span>
                        <br />
                        <span className="time">{timeLeft.hours}</span>
                      </div>
                      <div className="time-block">
                        <span className="label">Minutes</span>
                        <br />
                        <span className="time">{timeLeft.minutes}</span>
                      </div>
                      <div className="time-block">
                        <span className="label">Seconds</span>
                        <br />
                        <span className="time">{timeLeft.seconds}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row new-row">

                {/* <div className="comingsoon-signup">
                  <div className="email-form-comingsoon col-md-6">
                    <div class="join-us-comingsoon"><h4>Sign up for Newsletter</h4></div>
                    <div className="email-checkfield">

                      <div className="form-box">
                        <label htmlFor="email" className="mb-2">
                          Email<span className="color-asterisk">*</span>
                        </label>
                        <input
                          type="email" className="form-control email-sign-upbox" id="email" name="email" placeholder="Email" />
                        <span className="fa-solid fa-envelope field-icon"></span>
                      </div>
                    </div>


                    <div className="row">
                      <div className="mb-3 form-check accept-checkbox">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1"></input>
                        <label htmlFor="acceptance" class="form-check-label coming-soonlabel" for="exampleCheck1">
                          By joining the LFCoin Community, you agree that $LAL
                          coins are promotional and not linked to any other
                          offers or obligations. They hold no guaranteed value,
                          and $LAL is not liable for any losses or issues
                          arising from receiving or using the coins. No legal
                          claims or recourse will be accepted.
                        </label>

                      </div>
                    </div>
                    <div className="submit-btn-comingsoon">
                      <button  disabled={loading}>{loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit"
                        )}</button>
                    </div>
                  </div>
                </div> */}

                <div className="comingsoon-signup">
                  <div className="email-form-comingsoon col-md-6">
                    <div className="join-us-comingsoon">
                      <h4>Please sign up for Newsletter</h4>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="email-checkfield">
                        <div className="form-box">
                          <label htmlFor="email" className="mb-2">
                            Email<span className="color-asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control email-sign-upbox"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setErrorEmail("");
                            }}
                            maxLength={40}
                          />
                          <span className="fa-solid fa-envelope field-icon"></span>
                        </div>
                       
                      </div>
                      {errorEmail && (
                          <div className="validation-mess">
                            <p className="text-danger">{errorEmail}</p>
                          </div>
                        )}
                      <div className="row">
                        <div className="mb-3 form-check accept-checkbox">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                            checked={isAccepted}
                            onChange={(e) => setIsAccepted(e.target.checked)}
                          />
                          <label
                            htmlFor="exampleCheck1"
                            className="form-check-label coming-soonlabel"
                          >
                            By joining the LFCoin Community, you agree that $LAL coins are promotional and not linked to any other offers or obligations. They hold no guaranteed value, and $LAL is not liable for any losses or issues arising from receiving or using the coins. No legal claims or recourse will be accepted.
                          </label>
                        </div>
                        {errorAcceptance && (
                          <div className="validation-mess">
                            <p className="text-danger">{errorAcceptance}</p>
                          </div>
                        )}
                      </div>
                      <div className="submit-btn-comingsoon">
                        <button type="submit" disabled={loading}>
                          {loading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"></span>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>

                    </form>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="lend-cont-inner-in">
                      <p>
                        <strong>Disclosure: </strong>
                        LF Coin ($LAL) is a community-driven meme coin created to
                        celebrate sports fandom and foster community engagement.
                        LF Coin is not affiliated with the Los Angeles Lakers, the
                        National Basketball Association (NBA), or any associated
                        organizations. The project operates independently and is
                        solely focused on building a decentralized community of
                        sports enthusiasts. As a meme coin, LF Coin carries no
                        intrinsic value and should not be considered an investment
                        or security.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div className="ani-image-home">
            <img src={lineImage} alt="img" />
          </div>
        </div>
      )}
      {isSuccess ? <SuccessMessage /> : null}
      <div className="main-footer">
        <p>
          <i className="fa fa-copyright"></i> Copyright 2024 predesigned All
          rights Received
        </p>
      </div>
    </>
  );
};

export default Content;
