import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./App.css";
import "./style.css";
import "./Responsive.css";
import "./polygon.css";
import "rsuite/dist/rsuite.css";
import RouterPage from "../src/router/RouterPage";
import { ProfileProvider } from "../src/components/context/ProfileContext";

function App() {
  // const user = useSelector((state) => state.userStore.user);
  // let token = user.auth;
  // const ws = new WebSocket(process.env.REACT_APP_API_ENDPOINT_URL);

  // ws.onmessage = (event) => {
  //   const data = JSON.parse(event.data);

  //   if (data.type === "walletCount") {
  //     console.log(`Connected wallets: ${data.count}`);
  //     // Update the UI to show the number of connected wallets
  //   }
  // };
  return (
    <div className="App">
      <ProfileProvider>
        <RouterPage />
      </ProfileProvider>
    </div>
  );
}

export default App;
