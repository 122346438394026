import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Web3ReactProvider } from "@web3-react/core";
import { MoonPayProvider } from "@moonpay/moonpay-react";
import { Provider } from "react-redux";
import allConnections from "./connector";
import { store } from "./store";

const connections = allConnections.map(([connector, hooks]) => [
  connector,
  hooks,
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <MoonPayProvider apiKey={process.env.REACT_APP_API_KEY} debug>
      <Web3ReactProvider connectors={connections}>
        <App />
      </Web3ReactProvider>
    </MoonPayProvider>
  </Provider>
);

reportWebVitals();
