import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/user";
import signupNewsletterReducer from "./reducers/signupNewsletterSlice";

export const store = configureStore({
  reducer: {
    userStore: userReducer,
    signUpEvents: signupNewsletterReducer,
  },
});
