import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const signupNewsletter = createAsyncThunk(
  'newsletter/signup',
  async ({ email, is_agree }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT_URL}/newsletter/signup`, {
        email,
        is_agree,
      });
        return response?.data?.message; 
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message || 'An error occurred. Please try again.');
      }
      return rejectWithValue('An error occurred. Please try again.');
    }
  }
);

const newsletterSlice = createSlice({
  name: 'newsletter',
  initialState: {
    loading: false,
    error: null,
    success: false, 
  },
  reducers: {
    resetNewsletterState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false; 
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupNewsletter.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false; 
      })
      .addCase(signupNewsletter.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true; 
      })
      .addCase(signupNewsletter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      });
  },
});

export const { resetNewsletterState } = newsletterSlice.actions;

export default newsletterSlice.reducer;